export default {
  invalid_type: {
    email: 'email must be a valid email',
  },
  correct_url: 'Enter correct URL',
  field_required: 'This field is required!',
  email_invalid: 'Email is invalid',
  required: '{{name}} is required',
  pass_not_confirm: 'This password not confirm',
  max_length: '{{name}} should not exceed 255 characters',
  max_length_number: '{{name}} should not exceed {{number}} characters',
  min: '{{name}} is at least {{number}} characters ',
  password_must_match: 'Passwords must match',
  confirm_password_must_match: 'Confirm passwords must match',
  max: '{{name}} must be shorter than {{number}} characters',
  max_number: '{{name}} must be less than or equal to {{number}}',
  min_number: '{{name}} must be greater than or equal to {{number}}',
  must_number: '{{name}} must be numbers',
  date_before_now: '{{name}} must be before today',
  date_now: 'The date must be greater than the current date.',
  date_from: 'Date must be greater than date from.',
  is_Number: 'Must be a number',
  must_be_a_valid_data_URL: 'Must be a valid data URL',
  please_enter_website: 'Please enter website',
  image: {
    wrong_type: 'The image must be in the format png, jpg, jepg',
    empty: 'No image found',
    max_size: 'Image no more than 10MB',
  },
  isNumber: 'Phone number is not valid',
  day: 'Day',
  month: 'Month',
  year: 'Year',
  is_required: 'This is required',
  is_checked: 'This field must be checked',
  acceptTermsAndConditions: 'You need to accept with the term and conditions',
};
